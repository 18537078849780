import { graphql, StaticQuery } from "gatsby"
import Img from "gatsby-image"
import React from "react"
import AnalyticsImg from "../../content/assets/illustrations/b2bcloud/analytics.svg"
import CatalogImg from "../../content/assets/illustrations/b2bcloud/catalogs.svg"
import IntegrationsImg from "../../content/assets/illustrations/b2bcloud/integrations.svg"
import PromotionsImg from "../../content/assets/illustrations/b2bcloud/promotions.svg"
import QuotesImg from "../../content/assets/illustrations/b2bcloud/quotes.svg"
import UxImg from "../../content/assets/illustrations/b2bcloud/ux.svg"
import Layout from "../components/layout"
import SEO from "../components/seo"
import "../utils/css/screen.css"

const AboutPage = ({ data }) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout title={siteTitle} currentLink="/b2bcloud">
      <SEO
        title="Trunkroad integrates on-field and online B2B sales"
        description="Trunkroad integrates on-field and online B2B sales, enabling you to plan,
        execute and analyze your omnichannel sales and capture new demand."
        keywords={[
          `B2B`,
          `B2B Commerce`,
          `AI Sales`,
          `Sales Intelligence`,
          "B2B eCommerce",
          "Sales Artificial Intelligence",
          "B2B AI",
          "B2B Sales AI",
        ]}
      />

      <div className="banner">
        <Img
          fluid={data.imageFile.childImageSharp.fluid}
          className="banner-img"
        />
        <div className="banner-body">
          <h1 className="centered caption">
            World's most intelligent B2B Commerce platform.
          </h1>
          <h4>
            Trunkroad is an intelligent platform designed with brands,
            wholesalers and retailers in mind. Our extensive yet modular product
            integrates on-field and online B2B sales, enabling you to plan,
            execute and analyze your omnichannel sales and capture new demand.
          </h4>
        </div>
      </div>
      <div className="b2bsections text-center">
        <div className="section sec1">
          <div className="section-txt">
            <h3>Digital Catalogues.</h3>
            <div className="sec-img">
              <CatalogImg />
            </div>
            <h5 className="section-text">
              <p>
                Go digital with all of your product catalogues and customer
                information readily available from the cloud. Showcase your
                products with high-res images, stock availability and a
                sophisticated yet easy-to-use search functionality.
              </p>
            </h5>
          </div>
          <div className="img1">
            <CatalogImg />
          </div>
        </div>

        <div className="section sec2">
          <div className="section-txt">
            <h3>Beautiful UI.</h3>
            <div className="sec-img">
              <UxImg />
            </div>
            <h5 className="section-text">
              <p>
                Trunkroad has a fully intuitive and beautiful UI that makes
                creating product collections, customer groups, and tracking
                orders a breeze.
              </p>
            </h5>
          </div>
          <div className="img2">
            <UxImg />
          </div>
        </div>

        <div className="section sec3">
          <div className="section-txt">
            <h3>Promotions.</h3>
            <div className="sec-img">
              <PromotionsImg />
            </div>
            <h5 className="section-text">
              <p>
                Run promotions at a distributor and warehouse level. Sell more
                with smart schemes.
              </p>
            </h5>
          </div>
          <div className="img3">
            <PromotionsImg />
          </div>
        </div>

        <div className="section sec4">
          <div className="section-txt">
            <h3>Third Party Integrations.</h3>
            <div className="sec-img">
              <IntegrationsImg />
            </div>
            <h5 className="section-text">
              <p>
                Trunkroad integrates to your backend inventory management system
                / ERP in real-time, creating a simple and seamless ordering
                process for you and your customers.
              </p>
            </h5>
          </div>
          <div className="img4">
            <IntegrationsImg />
          </div>
        </div>

        <div className="section sec5">
          <div className="section-txt">
            <h3>Quotes Made Simple.</h3>
            <div className="sec-img">
              <QuotesImg />
            </div>
            <h5 className="section-text">
              <p>
                Interactive quotes and smart inventory check to let you see
                where you are before confirming a quote.
              </p>
            </h5>
          </div>
          <div className="img5">
            <QuotesImg />
          </div>
        </div>

        <div className="section sec6">
          <div className="section-txt">
            <h3>Analytics for Data Driven Selling.</h3>
            <div className="sec-img">
              <AnalyticsImg />
            </div>
            <h5 className="section-text">
              <p>
                Know more about what your customers are looking for and sell
                accordingly.
              </p>
            </h5>
          </div>
          <div className="img6">
            <AnalyticsImg />
          </div>
        </div>
      </div>
    </Layout>
  )
}

const indexQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    imageFile: file(relativePath: { eq: "HeroHub.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1360) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default props => (
  <StaticQuery
    query={indexQuery}
    render={data => (
      <AboutPage location={props.location} data={data} {...props} />
    )}
  />
)
